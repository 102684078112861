<template>
  <v-btn
    class="ml-2"
    min-width="0"
    text
    exact
    :disabled="status"
    title="Sync new receipt, but not receipt's update status"
    @click="syncData(filter)"
  >
    <v-icon>mdi-autorenew</v-icon>
  </v-btn>
</template>

<script>
  import itemReceiptApi from '../../../api/item-receipt'
  import store from '@/store'
  import { get } from 'vuex-pathify'
  import moment from 'moment'
  export default {
    name: 'SyncReceipt',
    data () {
      return {
        status: false,
        defaultFilter: {
          categoryId: 0,
          taskStatusId: 0,
          keyWord: '',
          order: 'recent_updated',
          date_form: 30,
        },
      }
    },
    computed: {
      ...get('receipt', ['filter']),
    },
    methods: {
      syncData (filter) {
        this.status = true
        this.defaultFilter.order = filter.order
        this.defaultFilter.date_form = filter.date_form
        itemReceiptApi.syncData().then(response => {
          store.set('receipt/get', this.filter).then(response => {
            console.log(response)
          })
          this.status = false
        }).catch(error => {
          console.log(error)
          this.status = false
        })
      },
    },
  }
</script>

<style scoped>

</style>
